module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-WBWRG2SG","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brit Türkiye","short_name":"Brit TR","description":"Kedi ve köpeklerimizin sağlığını önceleyen Türkiye'mizde bulabileceğiniz Brit markalı evcil hayvan mamalarını buradan inceleyebilir, detaylı bilgi alabilirsiniz.","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/brit-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7447192fb4e1d3ee349e13020ead1985"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"autoGenHomeLabel":"Anasayfa","useAutoGen":true,"defaultCrumb":{"location":{"pathname":"/"},"crumbLabel":"Anasayfa","crumbSeparator":" / "},"crumbLabelUpdates":[{"pathname":"/urunler","crumbLabel":"Ürünlerimiz"},{"pathname":"/urunler/kedi","crumbLabel":"Kedi"},{"pathname":"/urunler/kedi/brit-care","crumbLabel":"Brit Care"},{"pathname":"/urunler/kopek","crumbLabel":"Köpek"},{"pathname":"/kvkk","crumbLabel":"KVKK"},{"pathname":"/kullanim-kosullari","crumbLabel":"Kullanım Koşulları"},{"pathname":"/iletisim","crumbLabel":"İletişim"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
